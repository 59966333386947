<template>
  <div id="menu-detail" class="menu-detail bg-white" v-if="currentProduct !== null && !myIsLoading">
    <el-drawer
      v-model="drawer"
      title
      direction="btt"
      custom-class="item-option"
      size="70%"
      @open="handleOpenDrawer"
    >
      <div class="modal-dialog container m-0">
        <div class="modal-content">
          <div class="item-option">
            <div class="row" style="max-height: calc(100vh - 320px); overflow-y: auto;">
              <fieldset
                class="col-12 mb-2"
                v-for="items in drawerCurrentProduct"
                :key="items.title"
              >
                <legend>
                  {{ items.title }}
                  <span style="font-size: 1rem; color:red;" v-if="items.required">
                    ：(必選
                    <span v-if="items.option === 1">
                      <span
                        v-if="items.option_min === 0 && items.option_max > 0"
                      >，至多{{ items.option_max }}項</span>
                      <span
                        v-else-if="items.option_min > 0 && items.option_max === 0"
                      >，至少{{ items.option_min }}項</span>
                      <span
                        v-else-if="items.option_min > 0 && items.option_max > 0"
                      >，至少{{ items.option_min }}項、至多{{ items.option_max }}項</span>
                    </span>
                    )
                  </span>
                  <span style="font-size: 1rem" v-else>
                    <span
                      v-if="items.option_min === 0 && items.option_max > 0"
                    >(至多{{ items.option_max }}項)</span>
                    <span
                      v-else-if="items.option_min > 0 && items.option_max === 0"
                    >(至少{{ items.option_min }}項)</span>
                    <span
                      v-else-if="items.option_min > 0 && items.option_max > 0"
                    >(至少{{ items.option_min }}項、至多{{ items.option_max }}項)</span>
                  </span>
                </legend>
                <div class="btn-group-toggle" v-if="items.option === 0">
                  <div v-for="item in items.items" :key="item.id">
                    <label
                      class="btn btn-block btn-outline-gray"
                      :for="'option_' + item.addition_category_id + '_' + item.id"
                      :class="{'active':isOpationalItem(item.id, item.addition_category_id)}"
                      @click.self="handleClickOption(items.option, item.id, item.addition_category_id, items.option_max, items.required)"
                    >
                      <input
                        type="radio"
                        :name="'options[' + item.addition_category_id + ']'"
                        :value="item.id"
                        :id="'option_' + item.addition_category_id + '_' + item.id"
                        class="d-none"
                      />
                      {{ item.name }}
                      <span v-if="item.price !== 0">
                        {{
                        additionalItemPriceFormat(item.price)
                        }}
                      </span>
                      <span v-else>+$0</span>
                      <span class="material-icons-outlined">check</span>
                    </label>
                  </div>
                </div>
                <div class="btn-group-toggle" v-else>
                  <div v-for="item in items.items" :key="item.id">
                    <label
                      class="btn btn-block btn-outline-gray"
                      :for="'option_' + item.addition_category_id + '_' + item.id"
                      :class="{'active':isOpationalItem(item.id,item.addition_category_id)}"
                      @click.self="handleClickOption(items.option, item.id, item.addition_category_id, items.option_max, items.required)"
                    >
                      <input
                        type="checkbox"
                        :id="'option_' + item.addition_category_id + '_' + item.id"
                        :name="'options[' + item.addition_category_id + ']'"
                        :value="item.id"
                        class="d-none"
                      />
                      {{ item.name }}
                      <span v-if="item.price !== 0">
                        {{
                        additionalItemPriceFormat(item.price)
                        }}
                      </span>
                      <span v-else>+$0</span>
                      <span class="material-icons-outlined">check</span>
                    </label>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
    <el-form :model="currentProduct" ref="myFormRef">
      <div class="option-list">
        <app-carousel
          v-if="currentProduct.product_images.length"
          :list="currentProduct && currentProduct.product_images"
        />
        <div class="item-header border-bottom py-2">
          <div class="title">
            <h3>{{ currentProduct.title }}</h3>
          </div>
          <!--
          <div class="introduction">{{ currentProduct.summary }}</div>
          -->
          <div class="introduction" v-html="currentProduct.summary"></div>

          <div class="d-flex justify-content-between align-items-center">
            <product-price :item="currentProduct"></product-price>
            <img src="@/assets/images/share.svg" alt width="24" @click="share" v-if="environmentType !== 'external'" />
          </div>
          <div class="introduction">
            商品庫存：
            <span v-if="currentProduct.stock_unlimited">無限制</span>
            <span v-else>{{ currentProduct.stock }}件</span>
          </div>
          <div class="introduction">
            運送方式：
            <span v-if="currentProduct.shipping_type > 0">低溫</span>
            <span v-else>常溫</span>
            <span
              style="color: orange"
              v-if="getFreeDescriptionPrice(currentProduct.shipping_type) > 0"
            >
              (滿{{
              getFreeDescriptionPrice(currentProduct.shipping_type)
              }}元免運費)
            </span>
          </div>
        </div>
        <div class="item-option pt-4">
          <fieldset v-for="items in currentProduct.product_addition_categories" :key="items.id">
            <legend>
              {{ items.title }}
              <span style="font-size: 1rem; color:red;" v-if="items.required">
                ：(必選
                <span v-if="items.option === 1">
                  <span
                    v-if="items.option_min === 0 && items.option_max > 0"
                  >，至多{{ items.option_max }}項</span>
                  <span
                    v-else-if="items.option_min > 0 && items.option_max === 0"
                  >，至少{{ items.option_min }}項</span>
                  <span
                    v-else-if="items.option_min > 0 && items.option_max > 0"
                  >，至少{{ items.option_min }}項、至多{{ items.option_max }}項</span>
                </span>
                )
              </span>
              <span style="font-size: 1rem" v-else>
                <span
                  v-if="items.option_min === 0 && items.option_max > 0"
                >(至多{{ items.option_max }}項)</span>
                <span
                  v-else-if="items.option_min > 0 && items.option_max === 0"
                >(至少{{ items.option_min }}項)</span>
                <span
                  v-else-if="items.option_min > 0 && items.option_max > 0"
                >(至少{{ items.option_min }}項、至多{{ items.option_max }}項)</span>
              </span>
            </legend>
            <div class="btn-group-toggle" v-if="items.option === 0">
              <div v-for="item in items.items" :key="item.id">
                <label
                  class="btn btn-block btn-outline-gray"
                  :for="'option_' + item.addition_category_id + '_' + item.id"
                  :class="{'active':isOpationalItem(item.id, item.addition_category_id)}"
                  @click.self="handleClickOption(items.option, item.id, item.addition_category_id, items.option_max, items.required)"
                >
                  <input
                    type="radio"
                    :name="'options[' + item.addition_category_id + ']'"
                    :value="item.id"
                    :id="'option_' + item.addition_category_id + '_' + item.id"
                    class="d-none"
                  />
                  {{ item.name }}
                  <span v-if="item.price !== 0">
                    {{
                    additionalItemPriceFormat(item.price)
                    }}
                  </span>
                  <span v-else>+$0</span>
                  <span class="material-icons-outlined">check</span>
                </label>
              </div>
            </div>
            <div class="btn-group-toggle" v-else>
              <div v-for="item in items.items" :key="item.id">
                <label
                  class="btn btn-block btn-outline-gray"
                  :for="'option_' + item.addition_category_id + '_' + item.id"
                  :class="{'active':isOpationalItem(item.id,item.addition_category_id)}"
                  @click.self="handleClickOption(items.option, item.id, item.addition_category_id, items.option_max, items.required)"
                >
                  <input
                    type="checkbox"
                    :id="'option_' + item.addition_category_id + '_' + item.id"
                    :name="'options[' + item.addition_category_id + ']'"
                    :value="item.id"
                    class="d-none"
                  />
                  {{ item.name }}
                  <span v-if="item.price !== 0">
                    {{
                    additionalItemPriceFormat(item.price)
                    }}
                  </span>
                  <span v-else>+$0</span>
                  <span class="material-icons-outlined">check</span>
                </label>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
      <div v-if="shop_dm_mode_setting == null || shop_dm_mode_setting.data[1].cart===1">
        <div
          class="option-close row justify-content-center align-items-center w-100 fixed-bottom bg-light"
          v-if="handleSoldOutButton()"
          style="z-index: 9999"
        >
          <div class="col text-center">
            <button
              type="button"
              class="btn btn-sm bg-transparent text-main"
              id="categoryToggle"
              style="padding:2px 4px;"
              @click="onCancelButton"
            >
              回首頁
              <!-- <span class="material-icons-outlined">home</span> -->
            </button>
          </div>
          <div class="col">
            <product-counter v-model="num" />
          </div>
          <div class="col">
            <button
              type="button"
              class="btn bg-main btn-block shadow text-white my-2"
              @click="onAddToCartButton"
              style="padding: 4px 8px"
              :disabled="!isLogin || isCartCreating || currentProduct.price < 0"
            >{{ $t("PRODUCT.BUTTON.ADD_TO_CART") }}</button>
          </div>
        </div>
        <div
          class="option-close row justify-content-center align-items-center w-100 fixed-bottom bg-light"
          v-else
        >
          <div class="col">
            <button
              type="button"
              class="btn bg-dark btn-block shadow text-white my-2"
              id="buttonBack"
              @click="onCancelButton"
            >{{ $t("PRODUCT.BUTTON.BACK") }}</button>
          </div>
          <div class="col">
            <button
              type="button"
              class="btn bg-danger btn-block shadow text-white my-2"
            >{{ $t("PRODUCT.BUTTON.SOLD_OUT") }}</button>
          </div>
        </div>
      </div>
      <!-- DM模式 -->
      <div class="row fixed-bottom bg-light px-2 py-3" v-else>
        <div class="col-4 d-flex justify-content-center align-items-center" :class="{'col-12': environmentType === 'external'}">
          <button
            type="button"
            class="btn bg-transparent text-main"
            id="categoryToggle"
            style="padding:2px 4px;"
            @click="onCancelButton"
          >
            回首頁
            <!-- <span class="material-icons-outlined">home</span> -->
          </button>
        </div>
        <div class="col" v-if="environmentType !== 'external'">
          <!-- <a
          href="javascript: void(location.href = ('https://lineit.line.me/share/ui?url=' .concat(encodeURIComponent(location.href))));"
          title="分享給 LINE 好友"
        >
          <span class="btn btn-lg btn-block text-white" style="background-color: #06c755;">
            <i class="material-icons-outlined">share</i>
            分享給LINE好友
          </span>
          </a>-->
          <a href="#" title="分享給 LINE 好友" @click.prevent="share">
            <span class="btn btn-lg btn-block text-white" style="background-color: #06c755;">
              <i class="material-icons-outlined">share</i>
              分享給LINE好友
            </span>
          </a>
        </div>
      </div>
    </el-form>
  </div>
</template>
<style>
.menu-detail .item-header .introduction {
  word-break: break-all;
  white-space: break-spaces;
}
.menu-detail .item-header {
  height: auto;
}
.option-close .my-2 {
  /* height: 50px; */
}
.menu-detail .item-option fieldset:last-child {
  margin-bottom: 4rem;
}
.swal2-container {
  z-index: 9999 !important;
}
</style>
<style scoped>
.menu-detail {
  max-width: 540px;
  margin: 0 auto;
  padding: 15px 0;
}
/deep/ .el-drawer__header {
  margin-bottom: 0px !important;
}
</style>
<script>
import { computed, onBeforeUpdate, ref, inject, watch } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2";
import { formatMoney, additionalItemPriceFormat } from "@/utils/tools";
import storage from "@u/storage";
import { useLogin } from "@/hook/auth";
import AppCarousel from "@/components/AppCarousel.vue";
import ProductPrice from "@/components/ProductPrice.vue";
import ProductCounter from "@/components/ProductCounter.vue";
storage.config = { type: "session" };
import liff from "@line/liff";
import { ElMessage } from "element-plus";
import LineService from "@/services/line.service";

export default {
  name: "ShopProduct",
  components: {
    AppCarousel,
    ProductPrice,
    ProductCounter,
  },
  props: {
    isLoading: {
      type: Boolean,
    },
  },
  emits: ["onLoading"],
  setup(props, { emit }) {
    //=====
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const product_id = route.params.id;
    const store = useStore();
    const shop_shipping = inject("shop_shipping"); // 取得商店宅配資訊
    const isCreatedCartId = ref();
    const num = ref(1);
    const os = storage.getItem('os')
    const environmentType = ref(storage.getItem('environmentType'))

    // 取得宅配冷凍免運門檻 type:1 -> 低溫宅配 type:０ -> 常溫宅配
    const getFreeDescriptionPrice = (type) => {
      return type > 0
        ? shop_shipping.value["delivery"]["cold"]["free"]
        : shop_shipping.value["delivery"]["amb"]["free"];
    };

    // 判斷是否回到購物頁面
    const onGoBack = () => {
      if (storage.getItem("shop_category_id") > 0)
        router.push("/shop/category/" + storage.getItem("shop_category_id"));
      else router.push("/shop/category");
    };

    // 如果沒有此商品就回到購物頁面
    store
      .dispatch("products/fetchDetailProduct", product_id)
      .then(() => {
        opationalItemInit();
      })
      .catch(() => {
        if (!currentProduct.value) {
          onGoBack();
        }
      });
    // 取得商品詳細資料
    const currentProduct = computed(() => store.state.products.product);
    // console.log(currentProduct)
    const drawerCurrentProduct = ref([]); // 因為要判斷必選且沒有選才要顯示，所以抽屜的商品，用這個變數渲染

    const myIsLoading = computed(() => {
      emit("onLoading", store.state.products.isLoading);
      return props.isLoading;
    });
    const isCartCreating = computed(() => store.getters["carts/isCreating"]);
    const cartCreatedData = computed(() => store.getters["carts/createdData"]);
    const cartList = computed(() => store.getters["carts/cartList"]);
    const product_options = ref([]);
    const new_product_options = ref([]);
    const product_options_active = ref([]);
    const { isLogin } = useLogin();
    //Add By Bryant
    const shop_dm_mode_setting = computed(
      () => store.getters["shop/brand_dm_mode_setting"]
    );
    const drawer = ref(false);

    //methods
    // 選擇項目初始化
    const opationalItemsObj = ref({});
    const opationalItemInit = () => {
      currentProduct.value.product_addition_categories.forEach((item) => {
        // console.log(item)
        opationalItemsObj.value[
          `option_${item.items[0].addition_category_id}`
        ] = [];
      });
      drawerCurrentProduct.value = [
        ...currentProduct.value.product_addition_categories,
      ];
      drawerCurrentProduct.value = drawerCurrentProduct.value.filter(
        (item) => item.required === 1
      );
      // console.log(drawerCurrentProduct);
    };

    // 項目資料處理
    let _options = null;
    let categoryIdArr = [];
    const handleClickOption = (
      option,
      id,
      categoryId,
      option_max,
      required
    ) => {
      // console.log(opationalItemsObj.value[`option_${categoryId}`])

      // 判斷商品是否有重複
      const isOptionsRepeat = opationalItemsObj.value[
        `option_${categoryId}`
      ].some((item) => item === id);

      if (!isOptionsRepeat) {
        if (option === 1) {
          // 如果是複選，當選擇的項目數量等於上限，就不能再選擇
          if (
            opationalItemsObj.value[`option_${categoryId}`].length >=
              option_max &&
            option_max > 1
          ) {
            return;
          }
          // 但如果上限最多是一項，則可以任選
          if (option_max === 1) {
            opationalItemsObj.value[`option_${categoryId}`] = [];
          }
          if (required === 1) {
            // 因為下面設定移除已選的複選選項，就會一起移除categoryIdArr裡的id，所以選擇一次選項，就要將categoryId加入
            categoryIdArr.push(categoryId);
          }
        }
        // 沒重複，如果是單選，要把上一個選擇的移除
        if (option === 0) {
          opationalItemsObj.value[`option_${categoryId}`] = [];

          // const index = opationalItemsObj.value[
          //   `option_${categoryId}`
          // ].findIndex((item) => item === _options);
          // if (index !== -1)
          //   opationalItemsObj.value[`option_${categoryId}`].splice(index, 1);

          // 判斷類別是否有重複，如果有就不要加入categoryIdArr
          const isCategoryId = categoryIdArr.some(
            (item) => item === categoryId
          );
          if (!isCategoryId && required === 1) categoryIdArr.push(categoryId);
          _options = id;
        }
        opationalItemsObj.value[`option_${categoryId}`].push(id);
      } else {
        // 移除categoryId
        const categoryIndex = categoryIdArr.findIndex(
          (item) => item === categoryId
        );
        if (categoryIndex !== -1) categoryIdArr.splice(categoryIndex, 1);

        // 有重複，如果是複選，要將原本的移除
        if (option === 1) {
          const index = opationalItemsObj.value[
            `option_${categoryId}`
          ].findIndex((item) => item === id);
          if (index !== -1)
            opationalItemsObj.value[`option_${categoryId}`].splice(index, 1);
        }
        if (option === 0) {
          const index = opationalItemsObj.value[
            `option_${categoryId}`
          ].findIndex((item) => item === id);
          opationalItemsObj.value[`option_${categoryId}`].splice(index, 1);
          _options = null;
        }
      }
      // ======================
      // console.log(new_product_options.value)
      // radio_sum += 1;
      // product_options.value.forEach((item) => {
      //   if (item.type == "radio") {
      //     if (
      //       radio_sum % 2 == 0 &&
      //       radio_data[item.value] == true &&
      //       item.value == value
      //     ) {
      //       item.checked = false;
      //     }
      //     radio_data[item.value] = item.checked;
      //   }

      //   if (item.checked) {
      //     product_options_active.value[item.value].classList.add("active");
      //     console.log(product_options_active.value)
      //   } else {
      //     product_options_active.value[item.value].classList.remove("active");
      //   }
      // });
    };
    // 抽屜開啟時，要重新取得項目選項
    const handleOpenDrawer = () => {
      drawerCurrentProduct.value = [
        ...currentProduct.value.product_addition_categories,
      ];
      drawerCurrentProduct.value = drawerCurrentProduct.value.filter(
        (item) => item.required === 1
      );
      // console.log(drawerCurrentProduct);
      // console.log(categoryIdArr);
      let repeatCategoryObj = {};
      categoryIdArr.forEach((id) => {
        if (Object.prototype.hasOwnProperty.call(repeatCategoryObj, id)) {
          repeatCategoryObj[id] += 1;
        } else {
          repeatCategoryObj[id] = 1;
        }
        // console.log(repeatCategoryObj)
        const index = drawerCurrentProduct.value.findIndex(
          (item) => item.items[0].addition_category_id === id
        );
        // console.log(index);
        if (
          index != -1 &&
          repeatCategoryObj[id] >= drawerCurrentProduct.value[index].option_min
        ) {
          drawerCurrentProduct.value.splice(index, 1);
        }
      });
    };
    // class判斷是否有active
    const isOpationalItem = (id, categoryId) => {
      // console.log(opationalItemsObj.value[`option_${categoryId}`])
      if (typeof opationalItemsObj.value[`option_${categoryId}`] === "object") {
        const isChecked = opationalItemsObj.value[`option_${categoryId}`].some(
          (item) => item === id
        );
        return isChecked;
      }
    };

    // 判斷商品是否完售
    const handleSoldOutButton = () => {
      if (!currentProduct.value.is_available) return false;
      if (
        !currentProduct.value.stock_unlimited &&
        currentProduct.value.stock < 1
      )
        return false;
      return true;
    };
    // 回上一頁
    const onCancelButton = () => {
      if (storage.getItem("shop_category_id") > 0)
        router.push("/shop/category/" + storage.getItem("shop_category_id"));
      else router.push("/shop");
    };

    // 刪除購物車項目
    const delectCartItem = async () => {
      if (isCreatedCartId.value !== undefined) {
        await store.dispatch("carts/updateCartQuantity", {
          id: isCreatedCartId.value,
          quantity: 0,
        });
      }
    };
    // 加入購物車
    const onAddToCartButton = async () => {
      // console.log(opationalItemsObj.value);
      new_product_options.value = [];
      const opationalItemsArray = Object.keys(opationalItemsObj.value);
      opationalItemsArray.forEach((item) => {
        // console.log(opationalItemsObj.value[item]);
        new_product_options.value.push(opationalItemsObj.value[item]);
      });
      new_product_options.value = [].concat(...new_product_options.value);
      // console.log(new_product_options.value);
      let addition_product_ids = [];
      new_product_options.value.forEach((item) => {
        addition_product_ids.push(Number(item));
        // if (item.checked === true) {
        // }
      });

      // 如果項目已存在要先刪除再新增
      //await delectCartItem();
      store
        .dispatch("carts/addCartItem", {
          product_id: product_id,
          addition_product_ids: addition_product_ids,
          quantity: num.value,
          kind: 2,
        })
        .then((data) => {})
        .catch((err) => {
          onAlert("error");
        });
    };

    // 獲取購物車資料
    store.dispatch("carts/getCarts").then(() => {
      const currentProduct = cartList.value?.find((item) => {
        return item?.product.id === Number(product_id);
      });
      if (currentProduct?.quantity !== undefined) {
        num.value = currentProduct.quantity;
        isCreatedCartId.value = currentProduct.id;
      }
    });

    const onAlert = (type, msg = null) => {
      let title = type === "success" ? t("ALERT.SUCCESS") : t("ALERT.ERROR");
      let error_msg = "";
      if (msg) error_msg = msg;
      Swal.fire({
        title: t("ALERT.ADD_TO_CART", { msg: title }),
        html: "<span style='color:red'>" + error_msg + "</span>",
        icon: type,
        showConfirmButton: true,
        confirmButtonColor: "#fd7e14",
        confirmButtonText: "關閉",
        timer: 2000,
      });
    };
    onBeforeUpdate(() => {
      product_options.value = [];
      product_options_active.value = [];
      new_product_options.value = [];
    });

    const id = storage.getItem("liff-id");
    const share = async () => {
      const url_shop = window.location.href
      const url_liff = `https://liff.line.me/${id}/product/${product_id}`;
      const url = url_shop
      // const url = os === 'android' ? url_shop : url_liff
      const { title, price } = currentProduct.value;
      const text = `分享一樣商品給你「${title}」售價$${price}! ${url}`;
      const shareContents = [
        {
          type: "text",
          text: text,
        },
      ];
      if (currentProduct.value.product_images.length > 0) {
        shareContents.push({
          type: "image",
          originalContentUrl: currentProduct.value.product_images[0].image_url,
          previewImageUrl: currentProduct.value.product_images[0].image_url,
        });
        // console.log(text)
      }
      const result = await LineService.shareFriend(shareContents);
      if (result.status === "copied") {
        ElMessage({
          message: "已複製連結",
          type: "success",
        });
      } else if (result.status === "success") {
        ElMessage({
          message: "分享成功",
          type: "success",
        });
      } else if (result.status === "closed") {
        ElMessage({
          message: "尚未開啟分享訊息功能，請聯絡客服人員",
          type: "warning",
        });
      }
    };

    // 當數量為 0 時取消
    watch(
      () => num.value,
      async (newValue) => {
        if (newValue <= 0) {
          await delectCartItem();
          onCancelButton();
        }
      }
    );

    return {
      // data
      product_options,
      product_options_active,
      myFormRef: ref(),
      isLogin,
      num,
      drawer,
      drawerCurrentProduct,
      environmentType,

      // computed
      currentProduct,
      myIsLoading,
      isCartCreating,
      cartCreatedData,
      shop_dm_mode_setting,

      // method
      getFreeDescriptionPrice,
      handleClickOption,
      handleSoldOutButton,
      onCancelButton,
      onAddToCartButton,
      onAlert,
      onGoBack,
      formatMoney,
      additionalItemPriceFormat,
      isOpationalItem,
      handleOpenDrawer,
      share,
    };
  },
  watch: {
    cartCreatedData: function () {
      // console.log(this.cartCreatedData);
      if (this.cartCreatedData !== null && !this.isCartCreating) {
        if (this.cartCreatedData.is_add_to_cart === true) {
          // this.onAlert("success");
          this.drawer = false;
          this.onGoBack();
        } else {
          if (this.cartCreatedData.error.code == 12002) {
            this.onAlert("error", "產品已售完。");
            this.onGoBack();
          } else if (this.cartCreatedData.error.code == 12001) {
            this.onAlert("error", "產品已不存在。");
            this.onGoBack();
          } else {
            if (this.drawer)
              this.onAlert(
                "error",
                "請確認所選擇 " +
                  this.cartCreatedData.error.message_detail +
                  " 數量過少或過多"
              );
            this.drawer = true;
          }
        }
      }
    },
  },
};
</script>
